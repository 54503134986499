import React  from 'react';
import { ChangeEvent, useState } from "react";
import { toCSSVariables } from "../utils/theme-utils";
import { themePrimaryVariantGenerator, themeVariablesGenerator } from "../utils/theme-variables-generator";
import { designTokenGenerator } from "../utils/design-token-generator";
import ReactSyntaxHighlighter from 'react-syntax-highlighter';
import { shadesOfPurple } from 'react-syntax-highlighter/dist/esm/styles/hljs';

type tokenMode = 'simple' | 'tokenObj';

export default function DesignTokens() {
const [tokenMode, setTokenMode] = useState<tokenMode>('simple'); // [tokenObj, simple]

	const CSS_VAR = `
		:root {
			${toCSSVariables(themeVariablesGenerator("teal"))};


			&[data-primary="brand"] { 
				${toCSSVariables(themePrimaryVariantGenerator("brand"))} 
			}
			&[data-primary="blue"] { 
				${toCSSVariables(themePrimaryVariantGenerator("blue"))} 
			}
			&[data-primary="teal"] { 
				${toCSSVariables(themePrimaryVariantGenerator("teal"))} 
			}
			&[data-primary="aqua"] { 
				${toCSSVariables(themePrimaryVariantGenerator("aqua"))} 
			}
			&[data-primary="green"] { 
				${toCSSVariables(themePrimaryVariantGenerator("green"))}
			}
			&[data-primary="yellow"] {
				${toCSSVariables(themePrimaryVariantGenerator("yellow"))}
			}
			&[data-primary="orange"] { 
				${toCSSVariables(themePrimaryVariantGenerator("orange"))}
			}
			&[data-primary="red"] { 
				${toCSSVariables(themePrimaryVariantGenerator("red"))}
			}
			&[data-primary="pink"] { 
				${toCSSVariables(themePrimaryVariantGenerator("pink"))}
			}
		} 
	`;
	const CSS_DARK_VAR = `
		[data-theme="dark"]{
			${toCSSVariables(themeVariablesGenerator("teal", false, "dark"))};


			&[data-primary="brand"] { 
				${toCSSVariables(themePrimaryVariantGenerator("brand"))} 
			}
			&[data-primary="blue"] { 
				${toCSSVariables(themePrimaryVariantGenerator("blue"))} 
			}
			&[data-primary="teal"] { 
				${toCSSVariables(themePrimaryVariantGenerator("teal"))} 
			}
			&[data-primary="aqua"] { 
				${toCSSVariables(themePrimaryVariantGenerator("aqua"))} 
			}
			&[data-primary="green"] { 
				${toCSSVariables(themePrimaryVariantGenerator("green"))}
			}
			&[data-primary="yellow"] {
				${toCSSVariables(themePrimaryVariantGenerator("yellow"))}
			}
			&[data-primary="orange"] { 
				${toCSSVariables(themePrimaryVariantGenerator("orange"))}
			}
			&[data-primary="red"] { 
				${toCSSVariables(themePrimaryVariantGenerator("red"))}
			}
			&[data-primary="pink"] { 
				${toCSSVariables(themePrimaryVariantGenerator("pink"))}
			}
		}
	`;

	const TOKEN = JSON.stringify(designTokenGenerator(tokenMode !== 'simple'), null, 2);

	const tokenModeChange = (value: ChangeEvent<HTMLInputElement>) => {
		const newValue: tokenMode = !!value?.target?.checked ? 'tokenObj' : 'simple';
		setTokenMode(newValue);
	}

	const copy = (content: string) => {
		navigator.clipboard.writeText(content);
		console.log('copiado', content)
	}

	return (
		<div className="content-wrap row no-gutters">
			<section className="flex-grow-1 d-flex flex-column col-6" style={{ position:  'relative'}}>
				<header className="top-bar">
					<h1 className="subtitle flex-grow-1">Design Token JSON</h1>
					<div>
						<div 
							className="ffy-checkbox ffy-checkbox--switch ffy-checkbox--sm" title="Seta automaticamente a cor com melhor contraste ao selecionar a cor de fundo"
						>
							<label className="ffy-checkbox__wrap">
								<div className="ffy-checkbox__label margin__xs--right">Simples</div>
								<input type="checkbox" className="ffy-checkbox__input" onChange={(value) => tokenModeChange(value)} checked={tokenMode === 'tokenObj'} />
								<div className="ffy-checkbox__field">
								
								</div>
								<div className="ffy-checkbox__label margin__xs--right">TokenObj</div>
							</label>
						</div>
					</div>
				</header>
				<ReactSyntaxHighlighter
					language="json"
					wrapLines={true}
					wrapLongLines={true}
					showLineNumbers={true}
					showInlineLineNumbers={false}
					customStyle={{
						border: "1px solid #c3c3c3",
						borderRadius: "5px",
						margin: 0
					}}
					className="flex-grow-1 "
					style={shadesOfPurple}
				>
					{TOKEN}
				</ReactSyntaxHighlighter>
				<button 
					onClick={() => navigator.clipboard.writeText(TOKEN)} 
					className={`btn btn--default btn--sm`}
					style={{ position: 'absolute', bottom: 12, right: 23}}
				>
					<span className="btn__label">Copiar JSON</span>
				</button>
			</section>

			<section className="flex-grow-1 d-flex flex-column col-6" style={{ position:  'relative'}}>
				<div  style={{ position:  'relative', height: '50%'}} className="d-flex flex-column flex-grow-1 overflow-auto">
					<header className="top-bar">
						<h1 className="subtitle ">CSS Theme Var</h1>
					</header>
					<ReactSyntaxHighlighter
						language="css"
						wrapLines={true}
						wrapLongLines={true}
						showLineNumbers={true}
						showInlineLineNumbers={false}
						style={shadesOfPurple}
						customStyle={{
							border: "1px solid #c3c3c3",
							borderRadius: "5px",
							margin: 0
						}}
						className="flex-grow-1 "
					>
					
						{CSS_VAR}
					</ReactSyntaxHighlighter>
					<button 
						onClick={() => copy(CSS_VAR)} 
						className={`btn btn--default btn--sm`}
						style={{ position: 'absolute', bottom: 12, right: 23}}
					>
						<span className="btn__label">Copiar variáveis</span>
					</button>
				</div>
				<div  style={{ position:  'relative', height: '50%'}} className="d-flex flex-column flex-grow-1 overflow-auto">
					<header className="top-bar">
						<h1 className="subtitle ">CSS Dark Theme Var</h1>
					</header>
					<ReactSyntaxHighlighter
						language="css"
						wrapLines={true}
						wrapLongLines={true}
						showLineNumbers={true}
						showInlineLineNumbers={false}
						style={shadesOfPurple}
						customStyle={{
							border: "1px solid #c3c3c3",
							borderRadius: "5px",
							margin: 0
						}}
						className="flex-grow-1 "
					>
					
						{CSS_DARK_VAR}
					</ReactSyntaxHighlighter>
					<button 
						onClick={() => copy(CSS_DARK_VAR)} 
						className={`btn btn--default btn--sm`}
						style={{ position: 'absolute', bottom: 12, right: 23}}
					>
						<span className="btn__label">Copiar variáveis</span>
					</button>
				</div>
			</section>
		</div>
	)
}