import { borderRadius, colorsPalette, controls, elevations, fontFamilyMono, fontFamilySans, fontSizes, root, spacings } from "../data/tokens";
import { convertObjToToken, tokenValue } from "./theme-utils";
import { themeVariablesGenerator } from "./theme-variables-generator";





export function designTokenGenerator(convertToken: boolean){
	const _tokenColors = Object.entries(colorsPalette).map((color) => {
		return {
			[color[0]]: {
				...Object.entries(color[1]).map(variant => {
					return {
						[variant[0]]: tokenValue((variant as any)[1].hexString(), 'color', convertToken)
					}
				}).reduce((prev, curr) => ({...prev, ...curr}) , {})
			}
		}
	}).reduce((prev, curr) => ({...prev, ...curr}) , {});

	const _elevations = Object.entries(elevations).map(elevation => {
		return {
			[elevation[0]]: {
				value: {
					...elevation[1],
					"type": "dropShadow",
					"color": "{theme.color.ui.shadow}"
				},
				"type": "boxShadow"
			}
		}
	}).reduce((prev, curr) => ({...prev, ...curr}) , {});

	const _controls = Object.entries(controls).map(control => {
		return {
			[control[0]]: Object.entries(control[1]).map(([key, value]) => {
				return {
					[key]: tokenValue(value, 'dimension', convertToken)
				}
			}).reduce((prev, curr) => ({...prev, ...curr}) , {})
		}
	}).reduce((prev, curr) => ({...prev, ...curr}) , {});
	
	const _borderRadius = convertObjToToken(borderRadius, 'borderRadius', convertToken);
	const _spacing = convertObjToToken(spacings, 'spacing', convertToken);
	const _fontSize = convertObjToToken(fontSizes, 'fontSizes', convertToken);

	
	return {
		colors: _tokenColors,
		borderRadius: _borderRadius,
		spacings: _spacing,
		font: {
			size: _fontSize,
			// family: {
			// 	sans: tokenValue(fontFamilySans, 'fontFamilies', convertToken),
			// 	mono: tokenValue(fontFamilyMono, 'fontFamilies', convertToken)
			// }
		},
		//elevations: !!convertToken ? _elevations : elevations,
		controls: _controls,
		...themeVariablesGenerator("teal", convertToken) 
	};
}
 