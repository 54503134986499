import Values from "values.js";

function paletteColorGenerator(colorBase: any){
	let colorsList = {};
	//colorsList['white'] = new Values(colorBase).tint(100);
	(colorsList as any)[0] = new Values(colorBase).tint(97);
	(colorsList as any)[50] = new Values(colorBase).tint(90);
	(colorsList as any)[100] = new Values(colorBase).tint(75);
	(colorsList as any)[200] = new Values(colorBase).tint(50);
	(colorsList as any)[300] = new Values(colorBase).tint(25);
	(colorsList as any)[400] = new Values(colorBase).tint(0);
	(colorsList as any)[500] = new Values(colorBase).shade(25);
	(colorsList as any)[600] = new Values(colorBase).shade(50);
	(colorsList as any)[700] = new Values(colorBase).shade(75);
	(colorsList as any)[800] = new Values(colorBase).shade(90);
	(colorsList as any)[900] = new Values(colorBase).shade(97);
	//colorsList['black'] = new Values(colorBase).shade(100);
	
	return colorsList;
}

export default paletteColorGenerator;