import React  from 'react';
import { useState } from "react";
import { colorsPalette, fontSizes } from "../data/tokens";
import { best } from "wcag-color";
import ColorAside from "../components/color-aside";
import ColorScore from "../components/color-score";

function ContrastCheck() {
	const [backgroundColor, setBackgroundColor] = useState(["brand", 400]);
  const [foregroundColor, setForegroundColor] = useState(["brand", 0]);
  const [autoForegroundColor, setAutoForegroundColor] = useState(["brand", 0]);
  const [fontSize, setFontSize] = useState('16px');
  const [autoContrast, setAutoContrast] = useState(true);

	const _colors: any = Object.entries(colorsPalette).map((color) => {
		return {
			[color[0]]: {
				...Object.entries((color as any)[1]).map(variant => {
					return {
						[variant[0]]: (variant as any)[1].hexString()
					}
				}).reduce((prev, curr) => ({...prev, ...curr}) , {})
			}
		}
	}).reduce((prev, curr) => ({...prev, ...curr}) , {});


	const changeColorBg = (base: any, color: string) => {
		setBackgroundColor([base, color]);

		if(!!autoContrast){
			autoContrastColor(base, color);
		}
	}
	
	const changeColorFg = (base: any, color: string) => {
		setForegroundColor([base, color])
	}
	const changeFontSize = (value: string) => {
		setFontSize(value)
	}
	const changeAutoContrast = (value: boolean) => {
		setAutoContrast(value);
	}

	const autoContrastColor = (base: any, color: string) => {
		const bestColor = best(_colors[base][0], _colors[base][900], _colors[base][color])		
		const obj = Object.entries(_colors[base]).find(item => String(item[1]).toLocaleUpperCase() === String(bestColor).toLocaleUpperCase());
		
		if(!!obj){
			setForegroundColor([base, obj[0]]);
			setAutoForegroundColor([base, obj[0]]);
		}
	}

	


  return (
    <div className="content-wrap d-flex flex-column">
			{/* <header className="box spacing__md--all box--grey">
				<h1 className="display no-margin--all color-primary">Fretefy WCAG Checker <small className="font--light">Acessibilidade e legibilidade</small></h1>
			</header> */}
			<div className=" d-flex flex-grow-1 overflow-hidden">

				<ColorAside
					title="Cor do fundo"
					colors={_colors}
					onChangeColor={changeColorBg}
					autoContrast={autoContrast}
				/>
				<section className="box flex-grow-1 d-flex flex-column">
					<header className="header-toolbar d-flex align-items-center justify-content-center">
							<h2 className="no-margin--all title margin__sm--right">Font size: </h2>
							<div className="btn-group flex-wrap">
								{Object.values(fontSizes).map((item: any) => (
									<button 
										onClick={() => changeFontSize(item)} 
										className={`btn btn--default btn--sm ${item === fontSize ? 'btn--selected' : ''}`}
									>
										<span className="btn__label">{item}</span>
									</button>
								))}

							</div>

					</header>
					<main 
						className="flex-grow-1 d-flex align-items-center justify-content-center position-relative spacing__xxl--horizontal spacing__md--vertical text-center"
						style={{ 
							backgroundColor: _colors[backgroundColor[0]][backgroundColor[1]],
							color: _colors[foregroundColor[0]][foregroundColor[1]]
						}}
					>
						<p className="preview" style={{fontSize: fontSize}} contentEditable="true" suppressContentEditableWarning={true} spellCheck="false" >Ferramenta de verificação de acessibilidade para validar o contraste de cores  e tamanho da fonte com base no padrão WCAG 2.0 e WCAG 2.1.</p>

						<footer className="tool-description helper-text">
							<a href="https://www.w3.org/TR/WCAG20/#contrast-ratiodef" target="_blank" rel="noreferrer" style={{ color: best(_colors[foregroundColor[0]][0], _colors[foregroundColor[0]][900], _colors[backgroundColor[0]][backgroundColor[1]])}}>WCAG 2.0</a> &nbsp; |  &nbsp;
							<a href="https://www.w3.org/TR/WCAG21/#contrast-minimum" target="_blank" rel="noreferrer" style={{ color: best(_colors[foregroundColor[0]][0], _colors[foregroundColor[0]][900], _colors[backgroundColor[0]][backgroundColor[1]])}}>WCAG 2.1</a>
						</footer>

						<ColorScore background={_colors[backgroundColor[0]][backgroundColor[1]]} foreground={_colors[foregroundColor[0]][foregroundColor[1]]} fontSize={fontSize.replace('px', '')}/>
					</main>
				</section>
				<ColorAside
					title="Cor do texto"
					colors={_colors}
					isForeground={true}
					onChangeColor={changeColorFg}
					onAutoChange={changeAutoContrast}
					color={autoForegroundColor}
					baseColor={backgroundColor}
					fontSize={fontSize}
				/>
			</div>
		</div>
  );
}

export default ContrastCheck;
