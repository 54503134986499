import { score } from "wcag-color";
import { colorsPalette } from "../data/tokens";
import { ColorVariationsModel } from "../models/colors";
import { ThemeColorsModel, ThemeColorVariationModel, TokenObjModel } from "../models/design-token";

export type tokenTypes = 'figma' | 'tokenStudio' | null | undefined;
export function tokenValue(value: string, type: string, convertToken: boolean = false, tokenType: tokenTypes = "figma"): string | TokenObjModel {
	if(!convertToken)
		return value;

	switch (tokenType) {
		case "figma":{
			const figmaType = figmaTokenTypeFomat(type as tokenFormatTypes) 
			return {
				"$value": figmaTokenValueFomat(value, figmaType),
				"$type": figmaType
			}
		}
			
		
	case "tokenStudio":
		return {
			"value": value,
			"type": type 
		}
	
	
		default:
			return value;
	}
}

type tokenFormatTypes = 'spacing' | 'borderRadius' | 'fontSizes' | 'dimension';
function figmaTokenTypeFomat(type: tokenFormatTypes){
	switch (type) {
		case "spacing": 
		case "borderRadius": 
		case "fontSizes": 
		case "dimension": 
			return "number";
				
		default: return type;
	}
}
function figmaTokenValueFomat(value: string, type: string){
	switch (type) {
		case "number": 
			return Number(value?.replace('px', ''));
				
		default: return value;
	}
}
	

export function convertObjToToken(obj : any, type: string, convertToken: boolean = false){
	return Object.entries(obj).map((item) => {
		return {
			[item[0]]: tokenValue((item as any)[1], type, convertToken)
		}
	}).reduce((prev, curr) => ({...prev, ...curr}) , {})
}


// Retorna a cor de contraste
export function CheckContrastColor(base: string, colorContrast: ColorVariationsModel[] | any[], firstOrLast = "last"){
	let currentColor;
	const aa: any[] = [];
	const aaa: any[] = [];
	const aaLarge: any[] = [];

	// eslint-disable-next-line array-callback-return
	Object.entries(colorContrast).some(color => {
		const scoreFont = score(base, color[1].hexString());
		if(scoreFont === "AAA"){
			aaa.push(color[1].hexString());
		} else if(scoreFont === "AA"){
			aa.push(color[1].hexString())
		}
		else if(scoreFont === "AA Large"){
			aaLarge.push(color[1].hexString())
		}	

	});

	if(firstOrLast === "first"){
		if(aaa.length > 0){
			currentColor = aaa[0];
		} else if(aaa.length === 0 && aa.length > 0){
			currentColor = aa[0];
		} else if(aaa.length === 0 && aa.length === 0 && aaLarge.length > 0){
			currentColor = aaLarge[0];
		}
	} else {
		if(aaa.length > 0){
			currentColor = aaa[aaa.length - 1];
		} else if(aaa.length === 0 && aa.length > 0){
			currentColor = aa[aa.length - 1];
		} else if(aaa.length === 0 && aa.length === 0 && aaLarge.length > 0){
			currentColor = aaLarge[aaLarge.length - 1];
		}
	}

	if(!currentColor) console.log(base)
	return currentColor
}

// Retorna a lista base de cores do tema
export function colorVariant(color: string, lighter: number, light: number, normal: number, dark: number, contrastColorName: string, colorName: string, convertToken: boolean = false): Partial<ThemeColorsModel> {
	const colorArray = (colorsPalette as any)[color];
	const colorKeyName = colorName || color;
	const contrastName = contrastColorName || color;
	const contrastColorVariants = (colorsPalette as any)[contrastName];
	
	return {
		[colorKeyName]: {
			lighter: {
				background: tokenValue(colorArray[lighter].hexString(), 'color', convertToken),
				foreground: tokenValue(CheckContrastColor(colorArray[lighter].hexString(), contrastColorVariants, "first"), 'color', convertToken),
			},
			light: {
				background: tokenValue(colorArray[light].hexString(), 'color', convertToken),
				foreground: tokenValue(CheckContrastColor(colorArray[light].hexString(), contrastColorVariants, "first"), 'color', convertToken),
			},
			normal: {
				background: tokenValue(colorArray[normal].hexString(), 'color', convertToken),
				foreground: tokenValue(CheckContrastColor(colorArray[normal].hexString(), contrastColorVariants), 'color', convertToken),
			},
			dark: {
				background: tokenValue(colorArray[dark].hexString(), 'color', convertToken),
				foreground: tokenValue(CheckContrastColor(colorArray[dark].hexString(), contrastColorVariants), 'color', convertToken),
			}
		} as ThemeColorVariationModel
	} 
} 

export function colorPrimaryVariant(color: string, convertToken: boolean = false): Partial<ThemeColorsModel> {
	return {
		"primary": {
			lighter: {
				background: tokenValue(`{theme.color.${color}.lighter.background}`, 'color', convertToken),
				foreground: tokenValue(`{theme.color.${color}.lighter.foreground}`, 'color', convertToken),
			},
			light: {
				background: tokenValue(`{theme.color.${color}.light.background}`, 'color', convertToken),
				foreground: tokenValue(`{theme.color.${color}.light.foreground}`, 'color', convertToken),
			},
			normal: {
				background: tokenValue(`{theme.color.${color}.normal.background}`, 'color', convertToken),
				foreground: tokenValue(`{theme.color.${color}.normal.foreground}`, 'color', convertToken),
			},
			dark: {
				background: tokenValue(`{theme.color.${color}.dark.background}`, 'color', convertToken),
				foreground: tokenValue(`{theme.color.${color}.dark.foreground}`, 'color', convertToken),
			}
		} as ThemeColorVariationModel
	} 
}

// COnverte o objeto para variável do CSS
export function toCSSVariables (object : any) {
	const cssVariables: any[] = []

	const addCSSVariable = (currentValue: any, parentKeys: any) => {
		if(currentValue)
			Object.entries(currentValue).map(([key, value]) => {
				if (typeof value !== 'string' && typeof value !== 'number') {
					addCSSVariable(value, [...parentKeys, key])
				} else {
					cssVariables.push(`--${[...parentKeys, key].join('-',)}: ${String(value)?.indexOf('{') === -1 ? value : varReference(value as string)}`);
				}

				return {};
			})
	}

	addCSSVariable(object, [])

	return cssVariables.join(';\n')
}

// Converte o obj para a referencia 
export function varReference(ref: string){
	return `var(--${ref.replace('{', '').replace('}', '').split('.').join('-')})`;
}

// Retorna o obj base das variaóes de botões
export function buttonVariant(color: string, normal = 'dark', hover = 'normal', convertToken = false){
	return {
		foreground: tokenValue(`{theme.color.${color}.${normal}.foreground}`, 'color', convertToken),
		background: tokenValue(`{theme.color.${color}.${normal}.background}`, 'color', convertToken),
		border: tokenValue(`{theme.color.${color}.${normal}.foreground}`, 'color', convertToken),
		"hover": {
			foreground: tokenValue(`{theme.color.${color}.${hover}.foreground}`, 'color', convertToken),
			background: tokenValue(`{theme.color.${color}.${hover}.background}`, 'color', convertToken),
			border: tokenValue(`{theme.color.${color}.${normal}.background}`, 'color', convertToken),
		},
		"active": {
			foreground: tokenValue(`{theme.color.${color}.${normal}.foreground}`, 'color', convertToken),
			background: tokenValue(`{theme.color.${color}.${normal}.background}`, 'color', convertToken),
			border: tokenValue(`{theme.color.${color}.${normal}.background}`, 'color', convertToken),
		},
	}
}


// function objToSassMap(obj){
// 	return JSON.stringify(obj, null, 2).replace("{", "(").replace("}", ")").trim();
// }


// export function sassVars(){
// 	const colorList = [];
// 	Object.entries(colorsPalette).map((color) => {
// 		return colorList.push({
// 			[color[0]]: {
// 				...Object.entries(color[1]).map(variant => {
// 					return {
// 						[variant[0]]: variant[1].hexString()
// 					}
// 				}).reduce((prev, curr) => ({...prev, ...curr}) , {})
// 			}
// 		})
// 	}).reduce((prev, curr) => ({...prev, ...curr}) , {});

// 	return `
// // Paleta de cores
// ${colorList.map((cor) => {
// 	return Object.entries(cor).map(([key, value]) => {
// 		return `$${key}: ${objToSassMap(value)}`
// 	})
// }).join(';\n')};

// // Espaçamentos
// $spaces: ${objToSassMap(spacings)};

// // Border Radius
// $borderRadius: ${objToSassMap(borderRadius)};

// // FontSizes
// $fontSizes: ${objToSassMap(fontSizes)};

// // controls
// ${Object.entries(controls).map(([key, value]) => {
// 	return `$control-${key}: ${objToSassMap(value)}`;
// }).join(';\n')};

// 	`;
// }
