import React  from 'react';
import { useEffect, useState } from "react";
import toast from 'react-simple-toasts';
import { best } from 'wcag-color';
import { ColorsPalleteModel } from "../models/colors";
import ColorScore from "./color-score";

interface ColorAsideProps {
	title: string;
	colors: ColorsPalleteModel;
	isForeground?: boolean;
	autoContrast?: boolean;

	onChangeColor?: (baseColor: any, variation: any) => void;
	onAutoChange?: (checked: boolean) => void;

	color?: any;
	baseColor?: any;
	fontSize?: string;
}


function ColorAside(props: ColorAsideProps) {
  const [base, setBase] = useState("brand");
  const [color, setColor] = useState(400);
  const [autoContrast, setAutoContrast] = useState(true);

	const selectBase = (base: string) => {
		setBase(base);
		setColor(400);
		
		if(!!props.onChangeColor) props.onChangeColor(base, 400);

	}

	const selectColor = (color: number) => {
		setColor(color);	
		
		if(!!props.onChangeColor) props.onChangeColor(base, color);
	}

	const changeAuto = (value: any) => {
		if(!!props.isForeground) {
			if(!!props.onAutoChange) props.onAutoChange(value?.target?.checked);
		}
		
		setAutoContrast(value.target.checked);
		
	}

	useEffect(() => {
		if(!!props.color && !!autoContrast && !!props.isForeground){
			 setBase(props.color[0]);
			 setColor(props.color[1]);
		}
	}, [props.color, props.isForeground, autoContrast])

	const copyColor = (color: string) => {
		const emojis = [
			'😄','😃','😀','😊','☺','😉','😍','😘','😚','😗','😙','😜','😝','😛','😁','😌','😂','😅','😆','😋','😎','😇','😏','😺','😸','😻','😽','😼','😹','🔥','✨','🌟','💫', '👍','👌','👊','✌','👏','💛','💙','💜','💚','❤','💔','💗','💓','💕','💖','💞','💘'
		];
		const emoji = emojis[Math.floor(Math.random() * emojis.length)];

		navigator.clipboard.writeText(color.toUpperCase());

		toast(`${emoji} ${color.toUpperCase()} copiado!`);
	}


  return (
    <aside className="no-spacing--all box col-color d-flex flex-column overflow-hidden">
      <header className="header-toolbar ">
        <h2 className="subtitle no-margin--all flex-grow-1">
					{props.title}
				</h2>
				{!!props.isForeground && 
					<div 
						className="ffy-checkbox ffy-checkbox--switch ffy-checkbox--left ffy-checkbox--sm" title="Seta automaticamente a cor com melhor contraste ao selecionar a cor de fundo"
					>
						<label className="ffy-checkbox__wrap">
							<input type="checkbox" className="ffy-checkbox__input" onChange={(value) => changeAuto(value)} checked={!!autoContrast} />
							<div className="ffy-checkbox__field">
							
							</div>
							<div className="ffy-checkbox__label margin__xs--right">Auto</div>
						</label>
					</div>
				}
      </header>
      <div
        className={`flex-grow-1 d-flex overflow-hidden color-scroll-wrap ${
          !!props.isForeground ? "flex-row-reverse" : "flex-row"
        }`}
      >
        <nav className="flex-grow-1 color-scroll d-flex flex-column">
					<header className="color-scroll__header helper-text">Base</header>
          {Object.entries(props.colors).map((item) => (
            <button
              className={`color-item ${item[0] === base ? 'color-item--active' : ''}`}
              type="button"
              key={item[0]}
              onClick={() => selectBase(item[0])}
              style={{ 
								backgroundColor: item[1][400],
								color: best(item[1][0], item[1][900], item[1][400])
							}}
            >
              {item[0]}
            </button>
          ))}
        </nav>
        <div className="flex-grow-1 color-scroll d-flex flex-column ">
					<header className="color-scroll__header helper-text">Cor</header>
          {Object.entries((props.colors as any)[base]).map((item: any) => {
						
						if((!props.isForeground && (item[0] !== 0 && item[0] !== 900 && item[0] !== 'white' && item[0] !== 'black')) || !!props.isForeground) {
							return (
								<button
									className={`color-item ${item[0] === color ? 'color-item--active' : ''}`}
									type="button"
									key={item[0]}
									style={{
										backgroundColor: item[1],
										color: best((props.colors as any)[base][0], (props.colors as any)[base][900], item[1])
									}}
									onClick={() => selectColor(item[0])}
								>
									{item[0]}
									{!!props.baseColor && (
										<ColorScore background={(props.colors as any)[props.baseColor[0]][props.baseColor[1]]} foreground={item[1]} size="xs"  fontSize={props?.fontSize?.replace('px', '')}/>
									)}
								</button>
							)
						} else {
							return <></>;
						}
					})}
        </div>
      </div>
      <footer 
				className="color-selected"
				style={{
					backgroundColor: (props.colors as any)[base][color],
					color: best((props.colors as any)[base][0], (props.colors as any)[base][900], (props.colors as any)[base][color])
				}}
				onClick={() => copyColor((props.colors as any)[base][color])}
				title="Copiar valor"
			>
				<span id={'color'+ props.isForeground ? 'Foreground' : 'Background'}>{(props.colors as any)[base][color]}</span>
				<svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="fa-clone"><path fill="currentColor" d="M464 0H144c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h320c26.51 0 48-21.49 48-48v-48h48c26.51 0 48-21.49 48-48V48c0-26.51-21.49-48-48-48zM362 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h42v224c0 26.51 21.49 48 48 48h224v42a6 6 0 0 1-6 6zm96-96H150a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h308a6 6 0 0 1 6 6v308a6 6 0 0 1-6 6z" className=""></path></svg>
			</footer>
    </aside>
  );
}

export default ColorAside;
