import React  from 'react';
export default function Home(props: any) {


	return (
		<main className='home gap--md'>
			<div className='home__wrap'>
				<header className='text-shadow'>
					<h1 className="display2">Fretefy</h1>
					<h2 className='title'>Product Design System</h2>
				</header>

				<section className=' home__content d-flex flex-wrap gap--md'>
						{props.pages.map((page: any) => (
							<a href={!!page.slug ? `#${page.slug}` : page.url} className='card' onClick={() => !!page.slug ? props.setCurrentPage(page.slug) : null } target={!!page.url ? `_blank` : '_self'}>
								<i className="card__icon" dangerouslySetInnerHTML={{__html: page.icon}}>

								</i>
								<span className='card__content'>
									<span className="title font--bold d-block">{page.name}</span>
									<span className="title font--light d-block">{page.description}</span>
								</span>
							</a>
						))}
						
					
				</section>

			</div>
		</main>
	)
}