import React  from 'react';
import toast from 'react-simple-toasts';
import { best } from 'wcag-color';


function ColorVariations({colors, name}: any){
	const copyColor = (color: any) => {
		const emojis = [
			'😄','😃','😀','😊','☺','😉','😍','😘','😚','😗','😙','😜','😝','😛','😁','😌','😂','😅','😆','😋','😎','😇','😏','😺','😸','😻','😽','😼','😹','🔥','✨','🌟','💫', '👍','👌','👊','✌','👏','💛','💙','💜','💚','❤','💔','💗','💓','💕','💖','💞','💘'
		];
		const emoji = emojis[Math.floor(Math.random() * emojis.length)];

		navigator.clipboard.writeText(color.toUpperCase());

		toast(`${emoji} ${color.toUpperCase()} copiado!`);
	}
	

	return(
		<div className="flex-grow-1 d-flex flex-column">
			{!!colors && Object.entries(colors).map(color => (
				<div key={(color as any)[1].rgb} onClick={() => {copyColor((color as any)[1].hexString());}}	className="box-variant-color flex-grow-1 spacing__sm--all justify-content-center d-flex flex-column gap--sm" style={{ background: `#${(color as any)[1].hex}`, color: best("#fff", '#000', `#${(color as any)[1].hex}}`), minHeight: (color as any)[0] != 400 ? '46px' : '150px', maxHeight: (color as any)[0] != 400 ? 'auto' : '150px'}}>
					{color[0] === '400' && (
						<div className="caption flex-grow-1" style={{ textTransform: 'capitalize'}}>{name}</div>
					)}
					<div className="d-flex gap--sm align-items-center"> 
						<div className="flex-grow-1 small">{(color as any)[0]}</div>
						<div className="helper-text">{(color as any)[1].hexString().toUpperCase()}</div>
						
					</div>
				</div>
			))}
		</div>
	)
}

export default ColorVariations;