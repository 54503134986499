import React, { useMemo  } from "react";
import ColorContrastChecker from 'color-contrast-checker'; // https://github.com/bbc/color-contrast-checker/issues/17


function ColorScore(props: any) {
	// const [variant, setVariant] = useState('default');
	const bgColor = props.background;
  const fgColor = props.foreground;
  const fs = props.fontSize;
  const size = props.size || 'md';
	const ccc = useMemo(() => new ColorContrastChecker(), [])

	const isAA = useMemo(() => ccc.isLevelAA(bgColor, fgColor, fs), [fs, ccc, bgColor, fgColor])
  const isAAA = useMemo(() => ccc.isLevelAAA(bgColor, fgColor, fs), [fs, ccc, bgColor, fgColor])
  const isValid = useMemo(() => isAA || isAAA, [isAA, isAAA])

	return(
		<div 
			className={`color-score color-score--${size} ${isValid? 'color-score--success' : 'color-score--danger'}`}
			title={isAAA ? 'Contraste perfeito' : isAA ? 'Contraste mínimo aceitável' : 'Sem contraste suficuente'}
		>
			{isAAA ? 'AAA ✔️' : isAA ? 'AA ✔️' : size != 'xs' ? 'FALHOUZIS ❌' : '❌'}
		</div>
	);
}


export default ColorScore;
