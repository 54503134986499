import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-simple-toasts';
import Highlighter from "react-highlight-words";
import { idea } from 'react-syntax-highlighter/dist/esm/styles/hljs';

interface IconModel {
	icon: string;
	alias: string
}

function IconsList() {
	const spreadsheetUrl = 'https://docs.google.com/spreadsheets/d/1-g2amiasAKtnc2R5f38Grgc3AnvIiWrBciL1nlXHg9Q/edit?usp=sharing';
	const publicSpreadsheetUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRB_pcdywRjKkoUwJAPreNxVICNSOWjOASfsxG4t2L8RO0xK--peH_H0NqqcHjnVYRfnwNO7WXoMA57/pub?gid=0&single=true&output=csv';
	const [icons, setIconsList] = useState<IconModel[]>([]);
	const [data, setData] = useState<IconModel[]>([]);
	// const [searchText, setSearchText] = useState<string>();
	const [iconStyle, setIconStyle] = useState<string>('light');
	const [isLoading, setLoading] = useState<boolean>(false);

	const searchText = useRef();
	const typeSelected = useRef('alias');

	const iconOptions = [
		{ 
			id: 'alias',
			label: 'Fretefy'
		},
		{ 
			id: 'icon',
			label: 'FontAwesome'
		}
	]

	const iconStyles: string[] = ['light', 'regular', 'solid'];

	const colId = {
    icone: '#icone',
    rotulo: 'Rotulo',
    alias: '#alias'
  }

	useEffect(() => {
		loadSheets();
	}, []);


	const loadSheets =  () => {
		setLoading(true);
		axios.get(publicSpreadsheetUrl)
		.then((response) => {
			const parsedCsvData = parseCSV(response.data).filter(item => !!item['#alias']);
			const formatedIcons = formatIcons(parsedCsvData);
			setIconsList(formatedIcons as any);
			setData(removeDuplicated(formatedIcons));
		})
		.catch((error) => {
				console.error('Error fetching CSV data:', error);
		}).finally(() => {
			setLoading(false);
		});
  };

	function parseCSV(csvText) {
    const rows = csvText.split(/\r?\n/); // Split CSV text into rows, handling '\r' characters
    const headers = rows[0].split(','); // Extract headers (assumes the first row is the header row)
    const data: any[] = []; // Initialize an array to store parsed data
    for (let i = 1; i < rows.length; i++) {
        const rowData = rows[i].split(','); // Split the row, handling '\r' characters
        const rowObject = {};
        for (let j = 0; j < headers.length; j++) {
            rowObject[headers[j]] = rowData[j];
        }
        data.push(rowObject);
    }
    return data;
	}

	// formata o retorno para o objeto de icone
	function formatIcons(data: any[]){
    const newData = data.map(item => {
      let iconRow;
      if(!!item[colId.icone]){
        iconRow = {
          icon: item[colId.icone],
          alias: item[colId.alias]
        }
      }
			return iconRow;
    })

		return newData;
  }

	function filter(e?){
		let query;
		if(!!e) {
			query = e.target.value;
			searchText.current = query;
		} else {
			query = searchText.current;
		}

		setData(removeDuplicated(icons).filter(item => item[typeSelected.current]?.includes(query)));
	}

	const copyIcon = (icon: any) => {
		const emojis = [
			'😄','😃','😀','😊','☺','😉','😍','😘','😚','😗','😙','😜','😝','😛','😁','😌','😂','😅','😆','😋','😎','😇','😏','😺','😸','😻','😽','😼','😹','🔥','✨','🌟','💫', '👍','👌','👊','✌','👏','💛','💙','💜','💚','❤','💔','💗','💓','💕','💖','💞','💘'
		];
		const emoji = emojis[Math.floor(Math.random() * emojis.length)];
		navigator.clipboard.writeText(icon);
		toast(`${emoji} "${icon}" copiado!`);
	}

	function removeDuplicated(data){
		return data.filter((value, index, self) =>
			index === self.findIndex((t) => (
				t.alias === value.alias
			))
		)
	}

	function changeType(type){
		typeSelected.current = type;
		filter();
	}

	const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      const input = document.getElementById('searchInput');
			if(!!input){
				searchText.current = undefined;
				(input as HTMLInputElement).value = '';
				setData(removeDuplicated(icons));
			}
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

	return(
	<section className='spacing__md--all'>
		{!!isLoading && (
			<div className='loader-box loader-box--absolute'>
				<i className="fa-duotone fa-thin fa-spinner-third fa-3x fa-spin"></i>
				<p className="caption no-margin--all">Carregando icones...</p>
			</div>
		)}
		<div className="container">
			<div className='search'>
				<div className="position-relative">
					<input type="search" id='searchInput'  className="search__input" placeholder="Procurar icone..." onKeyDown={(e) => filter(e)} onChange={(e) => filter(e)} />

					<div className="search__options">
						<div className="btn-group">
							{iconOptions.map(item => (
								<button 
									className={`btn btn--default btn--sm ${typeSelected.current === item.id ? `btn--selected` : ''}`}
									onClick={() => changeType(item.id)}
								>
									<span className="btn__label">{item.label}</span>
								</button>

							))}

						</div>
					</div>
				</div>


			</div>

			<div className="d-flex flex-column justify-content-between align-items-center">
				<header className='spacing__md--bottom d-flex justify-content-between align-items-center spacing__md--horizontal' style={{ width: '100%'}}>
					<div>
						<h2 className='no-margin--all  title'>{data.length} icones</h2>
						<p className="no-margin--all helper-text">
							<a href={spreadsheetUrl} className='link' target='_blank' rel='noreferrer'>Editar icones <i className='fa-regular fa-arrow-up-right-from-square'></i></a>
						</p>

					</div>

					<div className="btn-group">
						{iconStyles.map(item => (
							<button 
								className={`btn btn--default btn--xs ${iconStyle === item ? `btn--selected` : ''}`}
								onClick={() => setIconStyle(item)}
							>
								<span className="btn__label">{item}</span>
							</button>

						))}

					</div>
				</header>
			
				<div className="row flex-wrap justify-content-center" style={{ rowGap: '24px', width: '100%'}}>
					{data.map(icon => (
						<div className="col-2">
							<div className="box icon-box text-center">
							<div className="icone text-secondary"><i className={'icon fa-'+iconStyle+' fa-'  + icon.icon}></i></div>
							<div className="label" title='Fretefy Alias' onClick={() => copyIcon(icon['alias']) }><Highlighter textToHighlight={icon.alias} searchWords={[typeSelected.current === 'alias' && searchText.current]}></Highlighter></div>
							<div className="helper-text text-muted" title='Font Awesome Icon'  onClick={() => copyIcon(icon['icon']) }><Highlighter textToHighlight={icon.icon} searchWords={[typeSelected.current === 'icon' && searchText.current]}></Highlighter></div>
						</div>
						</div>
					))}
						

				</div>
			</div>
		</div>

	</section>
	)
}

export default IconsList;