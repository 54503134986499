import { colorsPalette, root } from "../data/tokens";
import { ColorVariationsModel } from "../models/colors";
import { DesignTokenModel, DesignTokenThemeModel, ThemeColorsModel } from "../models/design-token";
import { buttonVariant, colorPrimaryVariant, colorVariant, tokenValue } from "./theme-utils";



export function themeVariablesGenerator(colorPrimaryName = "teal", convertToken = false, theme: 'light' | 'dark' | 'root' = 'root'): DesignTokenThemeModel | Partial<DesignTokenThemeModel> | undefined{
	const colorPrimary: ColorVariationsModel = (colorsPalette as any)[colorPrimaryName];

	//#region Light Theme

	const colorListLight = {
		...colorVariant('brand', 50, 200, 400, 600, "teal", "brand", convertToken),
		...colorVariant('blue', 50, 200, 400, 600, "blue", "blue", convertToken),
		...colorVariant('teal', 50, 200, 400, 600, "brand", 'teal', convertToken),
		...colorVariant('aqua', 50, 200, 400, 600, "aqua", "aqua", convertToken),
		...colorVariant('green', 50, 200, 400, 600, "green", "green", convertToken),
		...colorVariant('yellow', 50, 200, 400, 600, "yellow", "yellow", convertToken),
		...colorVariant('orange', 50, 200, 400, 600, "orange", "orange", convertToken),
		...colorVariant('red', 50, 200, 400, 600, "red", "red", convertToken),
		...colorVariant('pink', 50, 200, 400, 600, "pink", "pink", convertToken),
		...colorVariant('grey', 50, 200, 400, 600, "grey", "grey", convertToken),

		...colorPrimaryVariant(colorPrimaryName, convertToken),
	} as ThemeColorsModel;

	const designTokenRoot: Partial<DesignTokenModel> = {
		theme: {
			"color": {
				...colorListLight, 
				"ui": { 
					"brand": tokenValue('{theme.color.brand.normal.background}', 'color', convertToken),
					"primary": tokenValue('{theme.color.' + colorPrimaryName + '.normal.background}', 'color', convertToken),
					"secondary": tokenValue((colorsPalette as any).brand[300].hexString(), 'color', convertToken),
					"accent": tokenValue('{theme.color.orange.normal.background}', 'color', convertToken),
					"success": tokenValue('{theme.color.green.normal.background}', 'color', convertToken),
					"danger": tokenValue('{theme.color.red.normal.background}', 'color', convertToken),
					"warning": tokenValue('{theme.color.yellow.normal.background}', 'color', convertToken),
					"info": tokenValue('{theme.color.blue.normal.background}', 'color', convertToken),
					"neutral": tokenValue('{theme.color.brand.light.background}', 'color', convertToken),

					"foreground": tokenValue("{theme.dimmed.7}", 'color', convertToken),
					"foreground-highlight": tokenValue("{theme.color.ui.secondary}", 'color', convertToken),
					"foreground-muted": tokenValue("{theme.dimmed.5}", 'color', convertToken),
					"background": tokenValue("{theme.dimmed.1}", 'color', convertToken),
					"shadow": tokenValue(root.shadowColor, 'color', convertToken),
					"overlay": tokenValue('{theme.color.brand.dark.background}', 'color', convertToken),
					"border": tokenValue((colorsPalette as any).brand[50].hexString(), 'color', convertToken),

					"interactive": {
							text: tokenValue((colorPrimary as any)[500].hexString(), 'color', convertToken),
							"hover": {
								text: tokenValue((colorPrimary as any)[600].hexString(), 'color', convertToken)
							},
							"disabled": {
								text: tokenValue('{theme.dimmed.4}', 'color', convertToken)
							}
					}
				}, 
			}, 
			"dimmed":{
				"default": tokenValue((colorsPalette as any).grey[0].hexString(), 'color', convertToken),
				0: tokenValue((colorsPalette as any).grey[50].hexString(), 'color', convertToken),
				1: tokenValue((colorsPalette as any).grey[100].hexString(), 'color', convertToken),
				2: tokenValue((colorsPalette as any).grey[200].hexString(), 'color', convertToken),
				3: tokenValue((colorsPalette as any).grey[300].hexString(), 'color', convertToken),
				4: tokenValue((colorsPalette as any).grey[400].hexString(), 'color', convertToken),
				5: tokenValue((colorsPalette as any).grey[500].hexString(), 'color', convertToken),
				6: tokenValue((colorsPalette as any).grey[600].hexString(), 'color', convertToken),
				7: tokenValue((colorsPalette as any).grey[700].hexString(), 'color', convertToken),
			},
			"item": {
				foreground: tokenValue('{theme.color.primary.dark.background}', 'color', convertToken),
				background: tokenValue('{theme.dimmed.default}', 'color', convertToken),
				"active": {
					foreground: tokenValue("{theme.color.primary.lighter.foreground}", 'color', convertToken),
					background: tokenValue("{theme.color.primary.lighter.background}", 'color', convertToken),
					border: tokenValue("{theme.color.primary.normal.background}", 'color', convertToken)
				},
				"hover": {
					foreground: tokenValue("{theme.color.primary.lighter.foreground}", 'color', convertToken),
					background: tokenValue("{theme.color.primary.lighter.background}", 'color', convertToken),
					border: tokenValue("{theme.color.primary.normal.background}", 'color', convertToken)
				},
			},
			"control": {
				"default": {
					background: tokenValue("{theme.item.background}", 'color', convertToken),
					foreground: tokenValue("{theme.color.ui.foreground}", 'color', convertToken),
					border: tokenValue((colorsPalette as any).brand[100].hexString(), 'color', convertToken),
				}, 
				"hover": {
					background: tokenValue("{theme.item.background}", 'color', convertToken),
					foreground: tokenValue("{theme.item.hover.foreground}", 'color', convertToken),
					border: tokenValue("{theme.item.hover.border}", 'color', convertToken),
				},
				"active": {
					background: tokenValue("{theme.item.background}", 'color', convertToken),
					foreground: tokenValue("{theme.item.hover.foreground}", 'color', convertToken),
					border: tokenValue("{theme.item.active.border}", 'color', convertToken),
				},
				"disabled": {
					background: tokenValue("{theme.dimmed.2}", 'color', convertToken),
					foreground: tokenValue("{theme.dimmed.6}", 'color', convertToken),
					border: tokenValue("{theme.dimmed.3}", 'color', convertToken)
				},
				"required": {
					background: tokenValue("{theme.highlight.warning.background}", 'color', convertToken),
					foreground: tokenValue("{theme.highlight.warning.foreground}", 'color', convertToken),
					border: tokenValue("{theme.color.ui.warning}", 'color', convertToken),
				},
				"error": {
					background: tokenValue("{theme.highlight.danger.background}", 'color', convertToken),
					foreground: tokenValue("{theme.highlight.danger.foreground}", 'color', convertToken),
					border: tokenValue("{theme.color.ui.danger}", 'color', convertToken),
				},
				"valid": {
					background: tokenValue("{theme.item.background}", 'color', convertToken),
					foreground: tokenValue("{theme.color.ui.foreground}", 'color', convertToken),
					border: tokenValue("{theme.color.primary-dark-background}", 'color', convertToken),
				},
			},
			"highlight": {
				"neutral": {
					background: tokenValue("{theme.color.grey.light.background}", 'color', convertToken),
					foreground: tokenValue("{theme.color.grey.light.foreground}", 'color', convertToken),
					"solid": {
						background: tokenValue("{theme.color.grey.dark.background}", 'color', convertToken),
						foreground: tokenValue("{theme.color.grey.dark.foreground}", 'color', convertToken)
					} 
				},
				"warning": {
					background: tokenValue("{theme.color.yellow.lighter.background}", 'color', convertToken),
					foreground: tokenValue("{theme.color.yellow.lighter.foreground}", 'color', convertToken),
					"solid": {
						background: tokenValue("{theme.color.yellow.normal.background}", 'color', convertToken),
						foreground: tokenValue("{theme.color.yellow.normal.foreground}", 'color', convertToken), 
					} 
				},
				"success": {
					background: tokenValue("{theme.color.green.lighter.background}", 'color', convertToken),
					foreground: tokenValue("{theme.color.green.lighter.foreground}", 'color', convertToken),
					"solid": {
						background: tokenValue("{theme.color.green.normal.background}", 'color', convertToken),
						foreground: tokenValue("{theme.color.green.normal.foreground}", 'color', convertToken), 
					} 
				},
				"info": {
					background: tokenValue("{theme.color.blue.lighter.background}", 'color', convertToken),
					foreground: tokenValue("{theme.color.blue.lighter.foreground}", 'color', convertToken),
					"solid": {
						background: tokenValue("{theme.color.blue.light.background}", 'color', convertToken),
						foreground: tokenValue("{theme.color.blue.light.foreground}", 'color', convertToken), 
					}
				},
				"danger": {
					background: tokenValue("{theme.color.red.lighter.background}", 'color', convertToken),
					foreground: tokenValue("{theme.color.red.lighter.foreground}", 'color', convertToken),
					"solid": {
						background: tokenValue("{theme.color.red.normal.background}", 'color', convertToken),
						foreground: tokenValue("{theme.color.red.normal.foreground}", 'color', convertToken), 
					}
				},
				"accent": {
					background: tokenValue("{theme.color.orange.lighter.background}", 'color', convertToken),
					foreground: tokenValue("{theme.color.orange.lighter.foreground}", 'color', convertToken),
					"solid": {
						background: tokenValue("{theme.color.orange.normal.background}", 'color', convertToken),
						foreground: tokenValue("{theme.color.orange.normal.foreground}", 'color', convertToken)
					} 
				}
			},
			"buttons": {
				"primary": buttonVariant('primary', 'normal', 'light', convertToken),
				"default": {
					foreground: tokenValue("{theme.color.ui.brand}", 'color', convertToken),
					background:  tokenValue((colorsPalette as any).brand[50].hexString(), 'color', convertToken),
					border: tokenValue("{theme.color.brand.lighter.background}", 'color', convertToken),
					"hover": {
						foreground: tokenValue("{theme.color.primary.lighter.foreground}", 'color', convertToken),
						background: tokenValue("{theme.color.primary.lighter.background}", 'color', convertToken),
						border: tokenValue("{theme.color.brand.lighter.background}", 'color', convertToken),
					},
					"active": {
						foreground: tokenValue("{theme.color.brand.lighter.foreground}", 'color', convertToken),
						background: tokenValue("{theme.color.brand.lighter.background}", 'color', convertToken),
						border: tokenValue("{theme.color.brand.normal.background}", 'color', convertToken),
					},
				},
				"danger": buttonVariant('red', 'normal', 'light', convertToken),
				"success": buttonVariant('green', 'normal', 'light', convertToken),
				"warning": buttonVariant('yellow', 'normal', 'light', convertToken),
				"accent": buttonVariant('orange', 'normal', 'light', convertToken),
			}
		}
	}
	//#endregion


	//#region  Dark Theme
	const colorListDark = {
		...colorVariant('brand', 	600, 500, 400, 200, "teal", "brand", convertToken),
		...colorVariant('blue', 	700, 600, 500, 400, "blue", "blue", convertToken),
		...colorVariant('teal', 	700, 600, 500, 400, "brand", 'teal', convertToken),
		...colorVariant('aqua', 	700, 600, 500, 400, "aqua", "aqua", convertToken),
		...colorVariant('green', 	700, 600, 500, 400, "green", "green", convertToken),
		...colorVariant('yellow', 700, 600, 500, 400, "yellow", "yellow", convertToken),
		...colorVariant('orange', 700, 600, 500, 400, "orange", "orange", convertToken),
		...colorVariant('red', 		700, 600, 500, 400, "red", "red", convertToken),
		...colorVariant('pink', 	700, 600, 500, 400, "pink", "pink", convertToken),
		...colorVariant('defaultDark', 	800, 700, 500, 400, "grey", "grey", convertToken),

		...colorVariant(colorPrimaryName, 100, 200, 400, 600, "brand", "primary", convertToken),
	} as ThemeColorsModel;

	const designTokenDark = Object.assign({}, {
		theme: {
			"color": {
				...colorListDark,
				"ui": {
					"secondary": tokenValue((colorsPalette as any).brand[200].hexString(), 'color', convertToken),
					"foreground": tokenValue("{theme.dimmed.7}", 'color', convertToken),
					"foreground-highlight": tokenValue("{theme.color.ui.secondary}", 'color', convertToken),
					"foreground-muted": tokenValue("{theme.dimmed.6}", 'color', convertToken),
					// "background": tokenValue("{theme.dimmed.1}", 'color', convertToken),
					"shadow": tokenValue('rgba(0, 0, 0, .9)', 'color', convertToken),
					"overlay": tokenValue('{theme.color.brand.light.background}', 'color', convertToken),
					"border": tokenValue((colorsPalette as any).brand[500].hexString(), 'color', convertToken),
					"interactive": {
							text: tokenValue((colorPrimary as any)[300].hexString(), 'color', convertToken),
							"hover": {
								text: tokenValue((colorPrimary as any)[400].hexString(), 'color', convertToken)
							},
							"disabled": {
								text: tokenValue((colorPrimary as any)[700].hexString(), 'color', convertToken)
							}
					}
				}
			},
			"buttons": {
				"default": {
					foreground: tokenValue("{theme.color.brand.light.foreground}", 'color', convertToken),
					background:  tokenValue("{theme.color.brand.light.background}", 'color', convertToken),
					border: tokenValue("{theme.color.brand.lighter.background}", 'color', convertToken),
					"hover": {
						foreground: tokenValue("{theme.color.primary.lighter.foreground}", 'color', convertToken),
						background: tokenValue("{theme.color.primary.lighter.background}", 'color', convertToken),
						border: tokenValue("{theme.color.brand.lighter.background}", 'color', convertToken),
					},
					"active": {
						foreground: tokenValue("{theme.color.brand.lighter.foreground}", 'color', convertToken),
						background: tokenValue("{theme.color.brand.lighter.background}", 'color', convertToken),
						border: tokenValue("{theme.color.brand.normal.background}", 'color', convertToken),
					},
				},
			},
			"control": {
				"default": {
					background: tokenValue("{theme.item.background}", 'color', convertToken),
					foreground: tokenValue("{theme.color.ui.foreground}", 'color', convertToken),
					border: tokenValue("{theme.dimmed.5}", 'color', convertToken),
				}, 

				"disabled": {
					background: tokenValue("{theme.dimmed.1}", 'color', convertToken),
					foreground: tokenValue("{theme.dimmed.6}", 'color', convertToken),
					border: tokenValue("{theme.dimmed.3}", 'color', convertToken)
				},
				"valid": {
					background: tokenValue("{theme.item.background}", 'color', convertToken),
					foreground: tokenValue("{theme.color.ui.foreground}", 'color', convertToken),
					border: tokenValue("{theme.color.primary-normal-background}", 'color', convertToken),
				},
			},
			"highlight": {
				"neutral": {
					background: tokenValue("{theme.color.grey.light.background}", 'color', convertToken),
					foreground: tokenValue("{theme.color.grey.light.foreground}", 'color', convertToken),
					"solid": {
						background: tokenValue("{theme.color.grey.dark.background}", 'color', convertToken),
						foreground: tokenValue("{theme.color.grey.dark.foreground}", 'color', convertToken)
					} 
				}
			},
			"dimmed":{
				"default": tokenValue("{theme.dimmed.2}", 'color', convertToken),
				0: tokenValue((colorsPalette as any).defaultDark[800].hexString(), 'color', convertToken),
				1: tokenValue((colorsPalette as any).defaultDark[700].hexString(), 'color', convertToken),
				2: tokenValue((colorsPalette as any).defaultDark[600].hexString(), 'color', convertToken),
				3: tokenValue((colorsPalette as any).defaultDark[500].hexString(), 'color', convertToken),
				4: tokenValue((colorsPalette as any).defaultDark[400].hexString(), 'color', convertToken),
				5: tokenValue((colorsPalette as any).defaultDark[300].hexString(), 'color', convertToken),
				6: tokenValue((colorsPalette as any).defaultDark[200].hexString(), 'color', convertToken),
				7: tokenValue((colorsPalette as any).defaultDark[100].hexString(), 'color', convertToken),
			},
		}
	});


	if(theme === 'root') {
		return designTokenRoot as DesignTokenThemeModel;
	}
	else if(theme === 'dark') {
		return designTokenDark as Partial<DesignTokenThemeModel>;
	}
}


/**
 * VARIAÇÃO PARA ITEM PRIMÁRIO
 */
export function themePrimaryVariantGenerator(colorPrimaryName: string, convertToken = false){
	const colorPrimary: ColorVariationsModel = (colorsPalette as any)[colorPrimaryName];

 
	const designTokenVariant = {
		theme: {
			"color": {
				...colorPrimaryVariant(colorPrimaryName, convertToken), 
				ui: {
					"primary": tokenValue('{theme.color.' + colorPrimaryName + '.normal.background}', 'color', convertToken),
					"interactive": {
							text: tokenValue((colorPrimary as any)[500].hexString(), 'color', convertToken),
							"hover": {
								text: tokenValue((colorPrimary as any)[600].hexString(), 'color', convertToken)
							},
							"disabled": {
								text: tokenValue((colorPrimary as any)[100].hexString(), 'color', convertToken)
							}
					}
				}
			},

		}
	};

	return designTokenVariant;
}