import paletteColorGenerator from "../utils/paletteColorGenerator";

export const fontFamilySans = '"Nunito Sans", system-ui, -apple-system, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';
export const fontFamilyMono = '"SFMono-Regular", "Menlo", "Monaco", "Consolas", "Liberation Mono", "Courier New", monospace';

//#region Cores
// Paleta de Cores Base
export const colorsPalette = {
	"brand": 	{...paletteColorGenerator("#002a68")},
	"blue": 	{...paletteColorGenerator("#0087DB")},
	"teal": 	{...paletteColorGenerator("#00DBDB")},
	"aqua": 	{...paletteColorGenerator("#00F0B8")},
	"green": 	{...paletteColorGenerator("#00C703")},
	"red": 		{...paletteColorGenerator("#E53200")},
	"pink": 	{...paletteColorGenerator("#FF0073")},
	"orange": {...paletteColorGenerator("#F07800")},
	"yellow": {...paletteColorGenerator("#F0C400")},
	"grey": 	{...paletteColorGenerator("#D0D4DC")},
	"defaultDark": {...paletteColorGenerator("#00204E")},
}


export const root = {
	shadowColor: 'rgba(22, 22, 23, 0.15)'
} 
//#endregion

//#region Tamanhos
// Border Radius
export const borderRadius = {
	"xs": "2px",
	"sm": "3px",
	"md": "5px",
	"lg": "10px",
	"xl": "21px",
	"xxl": "32px",
	"xxxl": "48px"
}

// Spacing
export const spacings = {
	"xs": "6px",
	"sm": "12px",
	"md": "24px",
	"lg": "32px",
	"xl": "48px",
	"xxl": "64px"
}

// FontSize
export const fontSizes = {
	"xs": "12px",
	"sm": "14px",
	"md": "16px",
	"lg": "18px",
	"xl": "21px",
	"xxl": "32px",
	"xxxl": "48px"
}


// Variáveis com referencia
export const controls = {
	"xxs": {
		height: spacings.sm,
		padding: spacings.xs,
		raius: borderRadius.xs,
		"font-size": fontSizes.xs
	},
	"xs": {
		height: spacings.md,
		padding: spacings.sm,
		raius: borderRadius.sm,
		"font-size": fontSizes.xs
	},
	"sm": {
		height: spacings.lg,
		padding: spacings.sm,
		raius: borderRadius.sm,
		"font-size": fontSizes.sm
	},
	"md": {
		height: spacings.xl,
		padding: spacings.md,
		raius: borderRadius.md,
		"font-size": fontSizes.md
	},
	"lg": {
		height: "56px",
		padding: spacings.lg,
		raius: borderRadius.md,
		"font-size": fontSizes.lg
	},
}

export const elevations = {
	"elevation-1": {
		x: 0,
		y: 1,
		blur: 1
	},
	"elevation-2": {
		x: 0,
		y: (Number(spacings.xs.replace('px', '')) / 2) + 'px',
		blur: spacings.xs
	},
	"elevation-3": {
		x: 0,
		y: spacings.xs,
		blur: spacings.sm
	},
	"elevation-4": {
		x: 0,
		y: spacings.sm,
		blur: spacings.md
	},
	"elevation-5": {
		x: 0,
		y: spacings.md,
		blur: spacings.lg
	},
}

//#endregion
