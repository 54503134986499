import React  from 'react';
import ColorVariations from "../components/color-variations";
import { colorsPalette } from "../data/tokens";

function ColorPalette() {

	return(
		<section className="content-wrap d-flex overflow-auto">
			{Object.entries(colorsPalette).map(color => ( <ColorVariations colors={color[1]} name={color[0]} key={color[0]} />))}
		</section>
	)
}

export default ColorPalette;